
const $ = window.$ = require('jquery');

import Controller from './modules/controller';
import Swiper from'swiper/bundle';
import ScrollHint from 'scroll-hint'

(function($){

	Controller.standby();

	var Opening = {

		device : function() {
			return window.innerWidth > 750 ? 'PC' : 'SP'
		},
		init: function() {

			if(!$('.opening').get(0)) {
				return
			}

			if(this.device() == 'PC') {
				this.video = document.getElementsByTagName('video')[0];
				document.addEventListener('DOMContentLoaded', function() {
					this.video.addEventListener('loadeddata', function() {
						this.openingAnime();
						this.video.play();
					}.bind(this));
					this.video.src = '/assets/video/1st_view.mp4';
				}.bind(this))
			} else {
				this.openingAnime();
				// $('.opening').remove();
				// $('body').addClass('active');
				// $('.large-frame').addClass('active');
				// $('.mainVisual-heading').addClass('is-active');
			}
		},

		openingAnime: function() {
			var canvas = document.querySelector('.opening canvas');
			var ctx = canvas.getContext('2d');
			var img = document.createElement('canvas');
			var ctxImg = img.getContext('2d');
			var bg = new Image();
			var logo = new Image();
			var lines = [];

			if(this.device() == 'PC') {
				var imagePath = '/assets/img/opening/bg.png';
				var logoPath = '/assets/img/opening/logo.png';
				var alphaSpeed = 0.025;
				var lineSpeed = 150;
				var openSpeed = 200;
				var openDelay = 100;
			} else {
				var imagePath = '/assets/img/opening/sp_bg.png';
				var logoPath = '/assets/img/opening/sp_logo.png';
				var alphaSpeed = 0.025;
				var lineSpeed = 100;
				var openSpeed = 150;
				var openDelay = 100;
			}
			var i = 0;
			var s = 0;
			var step = 1000;
			var alpha = 0;

			var first = function() {
				img.width = bg.width;
				img.height = bg.height;
				canvas.width = bg.width;
				canvas.height = bg.height;

				if(Opening.device() == 'PC') {
					lines = [
						{
							x : bg.width / 2 + 1000,
							X : bg.width / 2 + 1000 - bg.height,
							y : 0,
							Y : bg.height,
							W : 50,
						},
						{
							x : bg.width / 2 + 800,
							X : bg.width / 2 + 800 - bg.height,
							y : 0,
							Y : bg.height,
							W : 50,
						},
						{
							x : bg.width / 2 + 600,
							X : bg.width / 2 + 600 - bg.height,
							y : 0,
							Y : bg.height,
							W : 50,
						},
						{
							x : bg.width / 2 + 400,
							X : bg.width / 2 + 400 - bg.height,
							y : 0,
							Y : bg.height,
							W : 50,
						},
						{
							x : bg.width / 2 + 200,
							X : bg.width / 2 + 200 - bg.height,
							y : 0,
							Y : bg.height,
							W : 30,
						},
						{
							x : bg.width / 2 - 1000,
							X : bg.width / 2 - 1000 + bg.height,
							y : 0,
							Y : bg.height,
							W : 50,
						},
						{
							x : bg.width / 2 - 800,
							X : bg.width / 2 - 800 + bg.height,
							y : 0,
							Y : bg.height,
							W : 50,
						},
						{
							x : bg.width / 2 - 600,
							X : bg.width / 2 - 600 + bg.height,
							y : 0,
							Y : bg.height,
							W : 50,
						},
						{
							x : bg.width / 2 - 400,
							X : bg.width / 2 - 400 + bg.height,
							y : 0,
							Y : bg.height,
							W : 50,
						},
						{
							x : bg.width / 2 - 200,
							X : bg.width / 2 - 200 + bg.height,
							y : 0,
							Y : bg.height,
							W : 50,
						},
						{
							x : bg.width / 2 - 0,
							X : bg.width / 2 - 0 + bg.height,
							y : 0,
							Y : bg.height,
							W : 50,
						},

					]
				} else {
					lines = [
						{
							x : bg.width / 2 + bg.height / 2,
							X : bg.width / 2 - bg.height / 2,
							y : 0,
							Y : bg.height,
							W : 80,
						}
					]
				}
				resize();
				window.addEventListener('resize', resize);

				$('.large-frame').addClass('active');
				animation();
			}

			var resize = function() {
				var scale = Math.max(window.innerHeight / canvas.height, window.innerWidth / canvas.width);
				canvas.style.height = canvas.height * scale + 'px';
				canvas.style.width = canvas.width * scale + 'px';
			}

			var animation = function() {
				if(animation.openEnd) {
					$('.opening').remove();
					$('body').addClass('active');
					$('.mainVisual-heading').addClass('is-active');
					return;
				}
				var delay = false;
				var scale = Math.max(window.innerHeight / canvas.height, window.innerWidth / canvas.width);
				var logoScale = Math.min(window.innerHeight / logo.height * 0.8, window.innerWidth / logo.width * 0.8, 1.0);
				var logoW = logo.width * logoScale / scale;
				var logoH = logo.height * logoScale / scale;
				var logoX = (canvas.width - logoW) / 2;
				var logoY = (canvas.height - logoH) / 2;

				alpha = alpha + alphaSpeed;
				ctxImg.globalCompositeOperation = 'source-over';
				ctxImg.clearRect(0, 0, canvas.width, canvas.height);
				ctxImg.globalAlpha = 1.0;
				ctxImg.drawImage(bg, 0, 0, bg.width, bg.height, 0, 0, canvas.width, canvas.height);
				ctxImg.globalAlpha = alpha;
				ctxImg.drawImage(logo, 0, 0, logo.width, logo.height, logoX, logoY, logoW, logoH)

				if(alpha >= 1) {
					if(!animation.alphaEnd) {
						delay = 500;
					}
					animation.alphaEnd = true;
				}
				ctx.globalCompositeOperation = 'source-over';
				ctx.clearRect(0, 0, canvas.width, canvas.height);

				if(animation.alphaEnd) {
					for(var j = 0; j <= i; j++) {
						var x = lines[j].x;
						var X = lines[j].X;
						var y = lines[j].y;
						var Y = lines[j].Y;
						var W = lines[j].W;

						if(j == i) {
							if(!animation.lineEnd) {
								s += lineSpeed;
								X = x + (X - x) / step * s;
								Y = y + (Y - y) / step * s;
							} else {
								lines[j].W += openSpeed;
							}
						}
						var x1 = x - W / 2;
						var x2 = x + W / 2;
						var X1 = X - W / 2;
						var X2 = X + W / 2;

						ctx.beginPath();
						ctx.moveTo(x1, y);
						ctx.lineTo(x2, y);
						ctx.lineTo(X2, Y);
						ctx.lineTo(X1, Y);
						ctx.closePath();
						ctx.fill();
					}

					if(!animation.lineEnd) {
						if(s > step) {
							s = 0;
							i = i + 1;
						}
						if(i == lines.length) {
							animation.lineEnd = true;
							delay = openDelay;
							i = i - 1;
						}
					} else {
						if(x1 < 0 && X1 < 0 && x2 > canvas.width && X2 > canvas.width) {
							animation.openEnd = true;
						}
					}
				}
				ctx.globalCompositeOperation = 'xor';
				ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, canvas.height);

				if(delay) {
					setTimeout(animation, delay);
				} else {
					window.requestAnimationFrame(animation);
				}
			}
			bg.addEventListener('load', function() {
				bg.loaded = true;
				if(bg.loaded && logo.loaded) {
					first();
				}
			});
			logo.addEventListener('load', function() {
				logo.loaded = true;

				if(bg.loaded && logo.loaded) {
					first();
				}
			})
			bg.src = imagePath;
			logo.src = logoPath;
		}
	}

	Opening.init();

	var Header = {

		init: function() {

			$(window).on('load scroll', this.fixed.bind(this));
			$(window).on('load scroll', this.hiddenVisual.bind(this));
			$('.menu-toggle').on('click', this.toggle.bind(this));
			$('.global-nav-item').on('click', this.hide.bind(this));

			$(window).on('resize:responsive', this.reset.bind(this));
		},
		fixed: function() {
			if(Controller.view.window.y >=  $('.header-inner').height()) {
				$('.header').addClass('is-fixed');
			}
			if(Controller.view.window.y < $('.header-inner').height()) {
				$('.header').removeClass('is-fixed');
			}
		},
		toggle: function(el) {
			$('.header').toggleClass('is-open');
			$('.global-nav-wrapper').fadeToggle(300)
		},
		hide: function() {
			if(Controller.device === 'SP') {
				$('.header').removeClass('is-open');
				$('.global-nav-wrapper').fadeOut(300)
			}
		},
		reset: function() {
			$('.global-nav-wrapper').removeAttr('style');
		},
		hiddenVisual: function() {
			if(Controller.view.window.y >=  $('.mainVisual').height()) {
				$('.mainVisual').addClass('is-hidden');
			}

			if(Controller.view.window.y <  $('.mainVisual').height()) {
				$('.mainVisual').removeClass('is-hidden');
			}
		}
	}

	Header.init();

	var SpMainVisual = {
		init: function() {
			var swiper;
			$(window).on('load resize', function(){

				if(Controller.device === 'PC') {
					if(swiper) {
						swiper.destroy();
                		swiper = undefined;
					}
				} else {
					if(swiper) {
						return {

						}
					} else {
						swiper = new Swiper('.mainVisual-slider', {
							loop: true,
							effect: 'fade',
							autoplay: {
							  delay: 4000,
							  disableOnInteraction: false,
							},
							speed: 2000,
						});
					}
				}
			})

		}
	}

	SpMainVisual.init();

	var Carousel = {

		initSwiper: function() {

			this.thumbSlider = null;
			this.mainSlider = null;

			if(Controller.device =='PC') {


				if(this.thumbSlider != null) {
					this.thumbSlider = null;
					this.mainSlider = null;
				}

				this.thumbSlider = new Swiper ('.thumb-slider', {
					speed: 300,
					slidesPerView: 11,
					spaceBetween: 9,
					centeredSlides :true,
					loop: true,
					slideToClickedSlide: true,
				});
				this.mainSlider = new Swiper ('.main-slider', {
					speed: 300,
					slidesPerView:1,
					zoom: true,
					observer: true,
					observeParents: true,
					observeSlideChildren: true,
					loop: true,
					navigation: {
						nextEl: '.main-slider .swiper-button-next',
						prevEl: '.main-slider .swiper-button-prev',
					},
				});
				this.thumbSlider.on('click', function(){
					this.mainSlider.slideToLoop(this.thumbSlider.realIndex);
				}.bind(this));


				this.mainSlider.on('slideChange', function(){
					this.thumbSlider.slideToLoop(this.mainSlider.realIndex);
				}.bind(this));


			} else {

				if(this.thumbSlider != null) {
					this.thumbSlider = null;
					this.mainSlider = null;
				}

				this.thumbSlider = new Swiper ('.thumb-slider', {
					speed: 300,
					slidesPerView: 5,
					spaceBetween: '1.6666666%',
					centeredSlides :true,
					loop: true,
					slideToClickedSlide: true,
				});
				this.mainSlider = new Swiper ('.main-slider', {
					speed: 300,
					slidesPerView:1,
					zoom: true,
					observer: true,
					observeParents: true,
					observeSlideChildren: true,
					loop: true,
					navigation: {
						nextEl: '.main-slider .swiper-button-next',
						prevEl: '.main-slider .swiper-button-prev',
					},
				});
				this.thumbSlider.on('click', function(){
					this.mainSlider.slideToLoop(this.thumbSlider.realIndex);
				}.bind(this));


				this.mainSlider.on('slideChange', function(){
					console.log('a')
					this.thumbSlider.slideToLoop(this.mainSlider.realIndex);
				}.bind(this));
			}
		}
	}
	$(window).on('resize:responsive', Carousel.initSwiper);

	var Utility = {
		init: function() {
			$('.smoothScroll').on('click', this.smoothScroll.bind(this));

			$('.banner-close').on('click', this.hide.bind(this));


			$(window).on('load scroll', this.toggle.bind(this));

			this.hint = null;
			// $(window).on('load resize', this.scrollHint.bind(this))

			window.onload = this.getParams;
		},
		getParams: function() {

			var url = new URL(window.location.href);
			var params = url.searchParams;

			if(!params.get('anchor')) {
				return
			}


			var query = document.getElementsByClassName(params.get('anchor'));
			var offset = $(query).offset().top;

			var position =  params.get('position');



			if(!position) {
				position = 0
			}


			$('body,html').animate({scrollTop: offset - parseInt(position,10)},300,'swing');
		},
		smoothScroll: function(el) {

			var className = $(el.currentTarget).data('anchor');
			var query = document.getElementsByClassName(className);
			var offset = $(query).offset().top;

			var position =  $(el.currentTarget).data('position');
			if(position === undefined) {
				position = 0
			}

			$('body,html').animate({scrollTop: offset - position},300,'swing');
			return false;
		},
		scrollHint: function() {

			if(Controller.device === 'SP') {
				this.hint = new ScrollHint('.price-table',{
					i18n: {
						scrollable: 'SCROLLABLE'
					}
				});
			} else {
				this.hint = null
			}

		},
		hide: function() {
			console.log('a')
			$('.mainvisual-banner').remove();
		},
		toggle: function() {
			if(Controller.device === 'PC') return;


			if(Controller.view.window.y > window.innerHeight / 2) {
				$('.online').addClass('is-active');
			}

			if(Controller.view.window.y <= window.innerHeight / 2) {
				$('.online').removeClass('is-active');
			}
		}
	}

	Utility.init()




})(jQuery)
