import ScrollMagic from 'ScrollMagic';

import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';

const Controller = {
	standby: function () {
		this.imgResponFlag = true;
		this.view = {};
		this.view.window = $(window);
		this.view.window.on('load scroll', this.scroll.bind(this));
		this.view.window.on('load resize', this.resize.bind(this));
		this.view.window.on('load resize:responsive', this.imgRespon.bind(this));
		this.ScrollController = new ScrollMagic.Controller();
		this.scrollMagic(this);
	},
	scroll: function () {
		this.view.window.y = this.view.window.scrollTop();
		this.view.window.h = this.view.window.innerWidth;
	},
	resize: function () {
		this.view.window.w = window.innerWidth;
		if (this.view.window.get(0).matchMedia('(max-width: 767px)').matches) {
			if (this.device != 'SP') {
				this.device = 'SP';
				this.view.window.trigger('resize:SP');
				this.view.window.trigger('resize:responsive');
			}
		} else {
			if (this.device != 'PC') {
				this.device = 'PC';
				this.view.window.trigger('resize:PC');
				this.view.window.trigger('resize:responsive');
			}
		}
	},
	imgRespon: function () {
		if (this.device === 'SP') {
			if (this.imgResponFlag) {
				$('img.respon').each(function (index) {
					$(this).attr('src', $(this).attr('src').replace(/^(.+)(\.[a-z]+)$/, '$1' + '.sp' + '$2'));
				});
				this.imgResponFlag = false;
			}
		}
		if (this.device === 'PC') {
			this.imgResponFlag = true;
			$('img.respon').each(function () {
				$(this).attr('src', $(this).attr('src').replace('.sp', ''));
			});
		}
	},
	scrollMagic: function () {
		if (typeof ScrollMagic != 'undefined') {
			var section = document.querySelectorAll('.js-anime:not(.is-active)');
			var controller = this.ScrollController;
			if (section.length != 0) {
				Array.prototype.slice.call(section).forEach(function (sec, index) {

					var hook = $(sec).data('hook') ? $(sec).data('hook') : 'onEnter';
					var offset = $(sec).data('offset') ? $(sec).data('offset')　: 0;

					var anime = new ScrollMagic.Scene({
							triggerElement: sec,
							triggerHook: hook,
							offset: offset,
							reverse: false
						})
						// .addIndicators( {name: "scroll"})
						.addTo(controller);
					anime.on('enter', function (event) {
						sec.classList.add('is-active');
					});
					anime.on('end', function () {
						anime.destroy(true);
					})
				});
			}
		}
	},
}

export default Controller;
